<template>
  <div class="demo-box" :style="`--phone-width: ${selectOpt.width}`">
    <div style="text-align: center;">
      设备型号：<el-select v-model="selectOpt" size="mini" value-key="label">
        <el-option
          v-for="item in optionList"
          :key="item.label"
          :label="`${item.label} （${item.width} x ${item.height} | Dpr:${item.dpr}）`"
          :value="item"
        />
      </el-select>
    </div>
    <div
      id="mobile-demo"
      :style="`padding: ${selectOpt.height * 0.06}px ${selectOpt.width * 0.04 }px ${selectOpt.height * 0.011}px ${selectOpt.width * 0.036}px;`"
    >
      <div class="iframe-box" :style="`width: ${selectOpt.width}px;height: ${selectOpt.height}px`">
        <iframe
          id="iframe"
          ref="iframe"
          allowTransparency="true"
          seamless
          :src="src"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          class="demo-container"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileDemo',
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      resizeObserver: null,
      selectOpt: {
        label: 'iPhone X',
        dpr: 3,
        width: 375,
        height: 791
      },
      optionList: [
        {
          label: 'iPhone 5',
          dpr: 2,
          width: 320,
          height: 568
        }, {
          label: 'iPhone 6/7/8',
          dpr: 2,
          width: 375,
          height: 667
        },
        //  {
        //    label: 'iPhone 6/7/8 Plus',
        //    dpr: 3,
        //    width: 414,
        //    height: 736
        //  },
        {
          label: 'iPhone X',
          dpr: 3,
          width: 375,
          height: 791
        },
        // {
        //   label: 'iPhone XR',
        //   dpr: 2,
        //   width: 414,
        //   height: 896
        // },
        // {
        //   label: 'iPhone XS Max',
        //   dpr: 3,
        //   width: 414,
        //   height: 896
        // },
        {
          label: 'iPhone 12/13 mini',
          dpr: 3,
          width: 375,
          height: 791
        }, {
          label: 'iPhone 12/13 (Pro)',
          dpr: 3,
          width: 390,
          height: 791
        }
        // {
        //   label: 'iPhone 12/13 Pro Max',
        //   dpr: 3,
        //   width: 428,
        //   height: 926
        // }
      ]
    }
  },
  computed: {
    // widthNum() {
    //   return this.height / 2.16 < 375 ? 375 : this.height / 2.16
    // },
    href() {
      const { origin } = window.location
      return `${origin}/zh/mobile-demo.html?pageKey=${this.$page.key}`
    },
    iframe() {
      return this.$refs.iframe
    }
  },
  mounted() {
    // this.initResize()
  },
  destroyed() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    initResize() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          this.height = entry.target.offsetHeight
        }
      })
      this.resizeObserver.observe(document.querySelector('#mobile-demo'))
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.demo-box {
  #mobile-demo {
    background: url(~@/assets/iPhone13.png);
    background-size: 100% 100%;
    position: sticky;
    top: 20px;
    margin-top: 20px;
    .iframe-box {
      position: relative;
      overflow: hidden;
      height: 100%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 47px;
      border-bottom-left-radius: 47px;
      #iframe {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}
</style>
