<template>
  <div class="edit">
    <!-- <el-form inline :model="form">
      <el-form-item label="brandingUrlPrefix">
        <el-input v-model="form.brandingUrlPrefix" />
      </el-form-item>
    </el-form> -->
    <div class="container">
      <mobile-demo ref="mobile-demo" :src="src" />
      <!-- <CodeMirror :value="formStr" @input="onInput" /> -->
    </div>
    <div class="edit-left">
      <div class="btn-control">
        <p style="margin: 0 0 10px 0; font-size: 14px;">布局管理</p>
        <el-button
          v-for="(item, index) in btnList"
          :key="index"
          class="tab-btn"
          size="mini"
          :class="{ active: item.value === activeTabName }"
          @click="activeTabName = item.value"
        >
          <svg-icon v-if="item.icon" :name="item.icon" /> {{ item.label }}
        </el-button>
      </div>
      <div class="control-form">
        <el-form
          v-if="activeTabName === 'basis'"
          ref="form"
          :model="form"
          :rules="formRules"
          label-position="left"
          size="mini"
          class="form"
        >
          <el-form-item label="图片排列样式" prop="imgShowType">
            <div class="layout-select">
              <div class="item" :class="{ active: form.imgShowType === 1 }" @click="form.imgShowType = 1">
                <p>
                  <svg-icon style="font-size: 80px;" name="add-image-icon" />
                </p>
                <span>多图轮播模式</span>
              </div>
              <div class="item" :class="{ active: form.imgShowType === 2 }" @click="form.imgShowType = 2">
                <p>
                  <svg-icon style="font-size: 80px;" name="pingpu" />
                </p>
                <span>单图平铺模式</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="`上传图片（最多支持5张）`" prop="imgUrls">
            <div
              v-for="(item, index) in form.imgUrls"
              :key="item.url + index"
              class="image-list"
            >
              <el-form-item
                :key="item.url + index"
                :prop="`imgUrls.${index}.url`"
                :rules="[{ required: true, message: `请上传图片`, trigger: 'blur' }]"
              >
                <images-upload
                  :width="70"
                  :height="70"
                  :action="uploadApi"
                  :size="50"
                  name="image"
                  :headers="headers"
                  :url="item.url ? [item.url] : []"
                  row-key="large"
                  notip
                  :max="1"
                  @on-success="({ data }) => $set(item, 'url', data)"
                  @remove="(index) => item.url = ''"
                />
              </el-form-item>
              <div class="img-form">
                <el-input v-model="item.jumpUrl" style="width: 100%;" placeholder="请输入跳转链接（选填）" />
                <div>
                  <el-button
                    type="text"
                    :disabled="index === 0"
                    size="mini"
                    @click="move('imgUrls', item, 'Up')"
                  >
                    上移
                  </el-button>
                  <el-button
                    type="text"
                    :disabled="form.imgUrls.length - 1 === index"
                    size="mini"
                    @click="move('imgUrls', item, 'Down')"
                  >
                    下移
                  </el-button>
                  <el-button
                    type="text"
                    style="color: #e8312f;"
                    size="mini"
                    @click="onDel('imgUrls', item)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </div>
            <el-button v-if="form.imgUrls.length < 5" class="add-image-btn" @click="onAddImage">
              <svg-icon style="font-size: 18px;" name="add-image-icon" /> 添加图片
            </el-button>
          </el-form-item>
          <el-form-item label="活动标题" prop="title">
            <el-input
              v-model="form.title"
              :maxlength="40"
              placeholder="请输入活动标题"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="活动简介" prop="intro">
            <el-input
              v-model="form.intro"
              :maxlength="100"
              type="textarea"
              placeholder="请输入活动简介"
              show-word-limit
              :rows="5"
            />
          </el-form-item>
          <el-form-item label="活动主办方" prop="organizer">
            <el-input
              v-model="form.organizer"
              :maxlength="40"
              placeholder="请输入活动主办方"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="活动封面（建议尺寸：750px*300px）" prop="coverUrl">
            <images-upload
              :action="uploadApi"
              name="image"
              :width="120"
              :height="68"
              :size="50"
              :headers="headers"
              :url="form.coverUrl ? [form.coverUrl] : []"
              row-key="large"
              notip
              :max="1"
              @on-success="({ data }) => $set(form, 'coverUrl', data)"
              @remove="(index) => form.coverUrl = ''"
            />
          </el-form-item>
          <el-form-item label="投票名称" prop="voteConfigInfo.title">
            <el-input
              v-model="form.voteConfigInfo.title"
              :disabled="form.isVote"
              :maxlength="40"
              show-word-limit
              placeholder="请输入投票名称"
            />
          </el-form-item>
          <el-form-item label="投票对象" prop="voteConfigInfo.voteObject">
            <el-radio-group v-model="form.voteConfigInfo.voteObject" :disabled="form.isVote" @change="form.brandingMaterialInfos = []">
              <el-radio :label="1">对作品投票</el-radio>
              <el-radio :label="2">对选手投票</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="投票设置" prop="voteConfigInfo.ruleParams">
            <el-radio-group v-model="form.voteConfigInfo.voteRule" :disabled="form.isVote" @change="voteRuleChange">
              <el-radio :label="1">固定次数</el-radio>
              <el-radio :label="2">周期次数</el-radio>
            </el-radio-group>
            <template v-if="form.voteConfigInfo.voteRule === 1">
              <div>
                每个账号每次活动中只能投
                <el-input-number
                  v-model.number="form.voteConfigInfo.ruleParams[0]"
                  :disabled="form.isVote"
                  :min="1"
                  :step="1"
                  step-strictly
                  label="描述文字"
                  style="width: 95px;"
                /> 次
              </div>
            </template>
            <template v-else-if="form.voteConfigInfo.voteRule === 2">
              <div>
                每个账号每
                <el-input-number
                  v-model.number="form.voteConfigInfo.ruleParams[0]"
                  :disabled="form.isVote"
                  :min="1"
                  label="描述文字"
                  :step="1"
                  step-strictly
                  style="width: 95px;"
                /> 天能投
                <el-input-number
                  v-model.number="form.voteConfigInfo.ruleParams[1]"
                  :min="1"
                  label="描述文字"
                  :step="1"
                  step-strictly
                  style="width: 95px;"
                /> 次
              </div>
            </template>
          </el-form-item>
          <el-form-item label="投票时间" prop="voteConfigInfo.date">
            <el-date-picker
              v-model="form.voteConfigInfo.date"
              :disabled="form.isVote"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
            />
          </el-form-item>
          <div>
            <el-form-item
              :label="`添加${form.voteConfigInfo.voteObject === 1 ? '作品' : '选手'}（${form.brandingMaterialInfos.length}个）`"
            >
              <div
                v-for="(item, index) in form.brandingMaterialInfos"
                :key="index"
                style="border: 1px solid #eee; padding: 8px 8px 8px 15px; margin-bottom: 10px;"
                :style="`background-color: ${item.isunfold ? '#f7f7fb' : ''}`"
              >
                <div v-if="!item.isunfold" class="material">
                  <el-form-item
                    :prop="`brandingMaterialInfos.${index}.materialName`"
                    :rules="[{ required: true, message: `请输入${form.voteConfigInfo.voteObject === 1 ? '作品' : '选手'}名称`, trigger: 'blur' }]"
                    :label="`${form.voteConfigInfo.voteObject === 1 ? '作品' : '选手'}名称`"
                  >
                    <el-input
                      v-model="item.materialName"
                      :disabled="item.isVote"
                      style="width: 200px;"
                      :maxlength="10"
                      show-word-limit
                    />
                  </el-form-item>
                  <el-form-item label="作品链接">
                    <el-input v-model="item.materialUrl" :disabled="item.isVote" style="width: 200px;" />
                  </el-form-item>
                  <el-form-item v-if="form.voteConfigInfo.voteObject == 2" label="选手简介">
                    <el-input
                      v-model="item.materialIntro"
                      :disabled="item.isVote"
                      style="width: 200px;"
                      type="textarea"
                    />
                  </el-form-item>
                  <el-form-item
                    :prop="`brandingMaterialInfos.${index}.imageUrl`"
                    :rules="[{ required: true, message: `请上传${form.voteConfigInfo.voteObject === 1 ? '作品图片' : '选手照片'}`, trigger: 'blur' }]"
                    :label="`${form.voteConfigInfo.voteObject === 1 ? '作品图片' : '选手照片'}`"
                  >
                    <div style="display: flex;">
                      <images-upload
                        :action="uploadApi"
                        name="image"
                        :width="70"
                        :height="70"
                        :size="50"
                        :headers="headers"
                        :url="item.imageUrl ? [item.imageUrl] : []"
                        :disabled="item.isVote"
                        notip
                        row-key="large"
                        :max="1"
                        @on-success="({ data }) => $set(item, 'imageUrl', data)"
                        @remove="(index) => item.imageUrl = ''"
                      />
                      <span style="margin-left: 10px; font-size: 12px; color: #5a5a5a;">建议尺寸：750*750px</span>
                    </div>
                  </el-form-item>
                </div>
                <div class="control">
                  <div class="title"><span v-if="item.isunfold">{{ item.materialName }}</span></div>
                  <div class="btn-wrap">
                    <el-button v-if="item.isunfold" type="text" @click="$set(item, 'isunfold', false)">展开</el-button>
                    <el-button v-else type="text" @click="$set(item, 'isunfold', true)">收起</el-button>
                    <el-button type="text" :disabled="index === 0 || item.isVote" @click="move('brandingMaterialInfos', item, 'Up')">
                      上移
                    </el-button>
                    <el-button
                      type="text"
                      :disabled="form.brandingMaterialInfos.length - 1 === index || item.isVote"
                      @click="move('brandingMaterialInfos', item, 'Down')"
                    >
                      下移
                    </el-button>
                    <el-button
                      type="text"
                      :disabled="item.isVote"
                      style="color: #e8312f;"
                      @click="onDel('brandingMaterialInfos', item)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
              <el-button
                style="width: 100%;"
                class="add-material-btn"
                type="primary"
                @click="onAdd"
              >
                <svg-icon name="add-material-icon" /> 添加{{ form.voteConfigInfo.voteObject === 1 ? '作品' : '选手' }}
              </el-button>
            </el-form-item>
          </div>
        </el-form>
        <el-button @click="onPublish">发布</el-button>
        <el-button type="primary" @click="onSave">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { lowcodeDoctorInfo } from '../../../utils/util'
import {cloneDeep} from 'lodash-es'
import brandSchema from './brandSchema.json'
import { getToken } from '@/utils/auth'
import mobileDemo from './mobile-demo.vue'
export default {
  name: 'Edit',
  components: { mobileDemo },
  data() {
    const checkRules = (rule, value, callback) => {
      const {voteRule} = this.form.voteConfigInfo
      if (voteRule === 1) {
        if (value.filter(item => item).length === 1) {
          callback()
        }
      } else if (voteRule === 2) {
        if (value.filter(item => item).length === 2) {
          callback()
        }
      }
      callback(new Error('请输入投票规则'))
    }
    return {
      activeTabName: 'basis',
      form: {
        title: '',
        intro: '',
        organizer: '',
        coverUrl: '',
        imgShowType: 1,
        brandingMaterialInfos: [],
        imgUrls: [],
        voteConfigInfo: {
          voteRule: 1,
          voteObject: 1,
          ruleParams: [],
          date: []
        },
        brandingUrlPrefix: `${lowcodeDoctorInfo().lowcodeUrl}/brand`
      },
      formRules: {
        imgUrls: [{ required: true, type: 'array', message: '请上传活动封面', trigger: 'change' }],
        title: [{ required: true, message: '请输入活动标题', trigger: 'blur' }],
        intro: [{ required: true, message: '请输入活动简介', trigger: 'blur' }],
        organizer: [{ required: true, message: '请输入活动主办方', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '请上传活动封面', trigger: 'blur' }],
        'voteConfigInfo.date': [{ required: true, message: '请选择投票时间', trigger: 'blur' }],
        'voteConfigInfo.ruleParams': [
          { required: true, validator: checkRules, message: '请输入投票规则', trigger: 'blur'}
        ],
        'voteConfigInfo.title': [{required: true, message: '请输入投票名称', trigger: 'blur'}],
        brandingMaterialInfos: [{ required: true, type: 'array', message: '请添加作品', trigger: 'blur' }]
      },
      btnList: [
        { label: '活动基础信息', icon: '', value: 'basis' }
        // { label: '标题文本', icon: 'list-icon', value: 'standard-text' },
        // { label: '图片广告', icon: 'list-icon', value: '' },
        // { label: '项目列表', icon: 'list-icon', value: '' },
        // { label: '视频列表', icon: 'list-icon', value: '' },
        // { label: '电梯导航', icon: 'list-icon', value: '' },
        // { label: '辅助分割', icon: 'list-icon', value: '' },
        // { label: '评论互动', icon: 'list-icon', value: '' },
        // { label: '活动投票', icon: 'list-icon', value: 'activitie-vote' }
      ],
      brandSchema,
      headers: {
        token: getToken()
      },
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  computed: {
    uploadApi() {
      return `${this.$API.imageUpload}?bucketType=104`
    },
    previewId() {
      return this.form.id || 'ebe2a138634c75041f6c406d2b703408'
    },
    formStr() {
      return JSON.stringify(this.form, null, 4)
    },
    src() {
      return `${lowcodeDoctorInfo().lowcodeUrl}/brand/${this.previewId}?accessFromType=1&managePreview=true`
      // return `http://localhost:5173/brand/${this.previewId}?accessFromType=1&isPreview=true&token=1_a50fe16274e8a03941b0016f8beccf96_da5acdd158a94a0899dfaae89c07a663`
    }
  },
  watch: {
    form: {
      handler(val) {
        if (val) {
          this.$refs['mobile-demo'].iframe.contentWindow.postMessage(this.formatParams(cloneDeep(val)), '*')
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.params.brandingId) {
      this.getDetail().then(() => {
        if (this.form.imgUrls.length === 0) {
          this.onAddImage()
        }
        if (this.form.brandingMaterialInfos.length === 0) {
          this.onAdd()
        }
      })
    }
  },
  methods: {
    onInput(val) {
      this.form = JSON.parse(val)
    },
    // 规则改变
    voteRuleChange(val) {
      if (val === 1) {
        this.form.voteConfigInfo.ruleParams = [1]
      } else {
        this.form.voteConfigInfo.ruleParams = [1, 1]
      }
    },
    iframeOnload() {
      this.$refs['mobile-demo'].iframe.contentWindow.postMessage(this.formatParams(cloneDeep(this.form)), '*')
    },
    // 获取详情
    async getDetail() {
      try {
        const params = { brandingId: this.$route.params.brandingId }
        const res = await this.$axios.get(this.$API.detailViewAll, { params })
        if (!res.imgUrls || res.imgUrls === 'null') {
          res.imgUrls = '[]'
        }
        res.imgUrls = JSON.parse(res.imgUrls).map(item => {
          if (!item.id) {
            item.id = this.$nanoid()
          }
          item.url = {
            large: item.url
          }
          return item
        })
        res.coverUrl = { large: res.coverUrl }
        res.brandingMaterialInfos = res.brandingMaterialInfos.map(item => {
          item.imageUrl = { large: item.imageUrl }
          item.isVote = res.isVote
          if (!item.id) {
            item.id = this.$nanoid()
          }
          return item
        })
        if (Object.prototype.toString.call(res.voteConfigInfo.ruleParams) === '[object String]') {
          res.voteConfigInfo.ruleParams = res.voteConfigInfo.ruleParams.split(',')
        }
        res.voteConfigInfo.date = [res.voteConfigInfo.voteStartTime, res.voteConfigInfo.voteEndTime]

        this.form = res

        this.$refs['mobile-demo'].iframe.onload = () => {
          setTimeout(() => {
            this.$refs['mobile-demo'].iframe.contentWindow.postMessage(this.formatParams(cloneDeep(this.form)), '*')
          }, 500)
        }

      } catch (error) {
        console.log('[ error ] >', error)
      }
    },
    // 上传图片
    onSuccess({ data }, target) {
      target = data
      console.log(target)
    },
    // 添加图片素材
    onAddImage() {
      this.form.imgUrls.push({
        id: this.$nanoid(),
        url: '',
        jumpUrl: ''
      })
    },
    // 上移/下移
    move(targetListName, row, direction) {
      this.form[targetListName].some((item, index) => {
        if (item.id === row.id) {
          const [row] = this.form[targetListName].splice(index, 1)
          let currentIndex = index
          if (direction === 'Up') {
            currentIndex = currentIndex - 1
          } else if (direction === 'Down') {
            currentIndex = currentIndex + 1
          }
          this.form[targetListName].splice(currentIndex, 0, row)
          return true
        }
        return false
      })
    },
    // 添加人或作品
    onAdd() {
      this.form.brandingMaterialInfos.push({
        id: this.$nanoid(),
        materialName: '',
        materialIntro: '',
        imageUrl: ''
      })
    },
    // 删除人或作品
    onDel(targetListName, row) {
      this.form[targetListName] = this.form[targetListName].filter(item => item.id !== row.id)
    },
    // 保存信息
    onSave(type) {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              const deepParams = cloneDeep(this.form)
              const params = this.formatParams(deepParams)
              const res = await this.$axios.post(this.$API.brandingSave, params)
              this.form.id = res
              resolve()
              if (type !== 'publish') {
                this.$message.success('保存成功')
                this.$router.back()
              }
            } catch (error) {
              this.$message.error(error.message)
              reject()
            }
          }
        })
      })
    },
    // 格式化参数
    formatParams(params) {
      if (Object.prototype.toString.call(params.voteConfigInfo.ruleParams) === '[object Array]') {
        params.voteConfigInfo.ruleParams = params.voteConfigInfo.ruleParams.join(',')
      }
      params.voteConfigInfo.voteStartTime = params.voteConfigInfo.date[0]
      params.voteConfigInfo.voteEndTime = params.voteConfigInfo.date[1]
      // 整理图片
      params.imgUrls = params.imgUrls.map(item => {
        item.url = item.url?.large || ''
        delete item.id
        return item
      })
      params.coverUrl = params.coverUrl.large
      params.brandingMaterialInfos = params.brandingMaterialInfos.map((item, index) => {
        item.orderIndex = index
        item.imageUrl = item.imageUrl.large
        delete item.id
        return item
      })
      params.imgUrls = JSON.stringify(params.imgUrls)
      params.showJson = '{}'
      delete params.voteConfigInfo.date
      return params
    },
    async axiosPublish() {
      try {
        const params = {
          status: 1,
          id: this.form.id
        }
        await this.$axios({
          method: 'post',
          url: this.$API.changeActivityStatus,
          params
        })
        this.$message.success('发布成功')
        this.$router.back()
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
    },
    // 发布活动
    async onPublish() {
      this.onSave('publish').then(() => {
        this.axiosPublish()
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.edit {
  display: flex;
  min-height: calc(100vh - 40px);
  .container {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .edit-left {
    background-color: #fff;
    border: 1px solid #eee;
    display: flex;
    .btn-control {
      width: 120px;
      padding: 23px 14px;
      box-sizing: border-box;
      border-right: 1px solid #eee;
      .tab-btn {
        width: 90px;
        padding: 10px 9px;
        text-align: center;
        border-color: transparent;
        background: #f6f7fb;
        border-radius: 3px;
        color: #0f0f0f;
        margin: 0 auto;
        margin-bottom: 10px;
        &:hover {
          background-color: #edeff7;
        }
      }
      .active {
        border-color: #038075;
      }
    }
    .control-form {
      flex: 1;
      padding: 23px 20px;
      box-sizing: border-box;
      overflow: hidden;
      .form {
        :deep(.el-form-item__label) {
          float: none;
          text-align: right;
        }
        .material {
          :deep(.el-form-item) {
            display: flex;
            .el-form-item__label {
              width: 80px;
              text-align: right;
            }
          }
        }
        .control {
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .btn-wrap {
            margin-left: 10px;
            flex-shrink: 0;
            overflow: hidden;
          }
        }
        .add-material-btn {
          height: 40px;
          background: #f5f5f5;
          border: 1px solid #e1e1e1;
          color: #787878;
          :deep(.el-button) span {
            color: #787878;
          }
        }
      }
      .layout-select {
        display: flex;
        .item {
          margin-right: 16px;
          text-align: center;
          >p {
            width: 80px;
            height: 80px;
            background-color: #f4f4f4;
            margin: 0;
            border: 1px solid transparent;
            border-radius: 3px;
            overflow: hidden;
            transition: all 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          >span {
            font-size: 12px;
            font-weight: 400;
            color: #5a5a5a;
            line-height: 12px;
          }
        }
        .active {
          span {
            color: #3d61e3;
          }
          p {
            border: 1px solid #3d61e3;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              border: 10px solid transparent;
              border-top: 10px solid #3d61e3;
              border-right: 10px solid #3d61e3;
            }
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 10px;
              height: 6px;
              border-top: none;
              border-right: none;
              border-bottom: 2px solid #fff;
              border-left: 2px solid #fff;
              z-index: 1;
              transform: rotateZ(-40deg);
            }
          }
        }
      }
      .image-list {
        display: flex;
        border: 1px solid #eee;
        margin-bottom: 15px;
        :deep(.upload-container) {
          width: 70px;
          line-height: 0px;
          .images,
          .el-upload-dragger {
            border: none;
            border-radius: 0;
            background-color: #e3e3e3;
          }
        }
        .el-form-item {
          margin-bottom: 0;
        }
        .img-form {
          flex: 1;
          overflow: hidden;
          flex-shrink: 0;
          padding: 6px;
          box-sizing: border-box;
          text-align: right;
        }
      }
      :deep(.el-progress) {
        position: absolute;
      }
      .add-image-btn {
        width: 100%;
        height: 40px;
        background: #f5f5f5;
        border: 1px solid #e1e1e1;
        color: #787878;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.el-button) span {
          color: #787878;
        }
        .svg-icon {
          transform: translateY(2px);
        }
      }
    }
  }
}
</style>
